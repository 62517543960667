import React from 'react'
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

import { FaAngleDown } from "react-icons/fa6";

import { useState, useEffect } from 'react';

import { CgMenuRight } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

import { LuPhoneCall } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";


const Navbar = () => {

  // For main menu sidebar
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // for moblie sub menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className='flex justify-center items-center absolute inset-x-0 top-0 z-9999'>
      <div className="container py-[18px] flex justify-between items-center">
        <div className='header-logo'>
          <Link to="/">
            <img src={logo} alt="Site_Logo" className='h-[55px]' />
          </Link>
        </div>
        <nav className='header-nav d-none-1024'>
          <ul className='flex font-medium'>
            <li><Link to="/"><span className='menu-hover'>Home</span></Link></li>
            <li><a href='#about-us'><span className='menu-hover'>About Us</span></a></li>
            {/* <li><Link to="/about-us"><span className='menu-hover'>About Us</span></Link></li> */}
            <li className='dropdown'><Link to="#"><span className='menu-hover'>Our Services </span><FaAngleDown /> </Link>
              <ul className='dropdownmenu'>
                <li><a href="#web-development"><span className='menu-hover'>Web Development</span></a></li>
                {/* <li><Link to="/web-development"><span className='menu-hover'> Web Development</span></Link></li> */}
                <li><a href="#graphic-design"><span className='menu-hover'>Graphic Design</span></a></li>
                {/* <li><Link to="/graphic-design"><span className='menu-hover'>Graphic Design</span></Link></li> */}
                <li><a href="#seo"><span className='menu-hover'>SEO</span></a></li>
                {/* <li><Link to="/seo"><span className='menu-hover'>SEO</span></Link></li> */}
                <li><a href="#social-media"><span className='menu-hover'>Social Media Marketing</span></a></li>
                {/* <li><Link to="/social-media-marketing"><span className='menu-hover'>Social Media Marketing</span></Link></li> */}
                <li><a href="#content-writing"><span className='menu-hover'>Content Writing</span></a></li>
                {/* <li><Link to="/content-writing"><span className='menu-hover'>Content Writing</span></Link></li> */}
                <li><a href="#ebook-writing"><span className='menu-hover'>Ebook Writing</span></a></li>
                {/* <li><Link to="/ebook-writing"><span className='menu-hover'>Ebook Writing</span></Link></li> */}
              </ul>
            </li>
            <li><a href="#projects"><span className='menu-hover'>Our Projects</span></a></li>
            {/* <li><Link to="/projects"><span className='menu-hover'>Our Projects</span></Link></li> */}
            <li><a href='#contact'><span className='menu-hover'>Contact Us</span></a></li>
            {/* <li><Link to="/contact-us"><span className='menu-hover'>Contact Us</span></Link></li> */}
          </ul>
        </nav>
        <div className='header-contact-info d-none-1024'>
          <div>

          </div>
          <a href='#contact' className='btn-solid-yellow'>Contact Us</a>
          {/* <Link to="/contact" className='btn-solid-yellow'>Contact Us</Link> */}
        </div>
        {/* ------------ Moblie nav starts ------------ */}
        <div className='moblie-toggle-nav'>
          <button className={`menu-btn ${isOpen ? 'open' : ''}`} onClick={toggleSidebar} aria-label='menu'>
            {isOpen ? <RxCross2 /> : <CgMenuRight />}
          </button>
          <div className={`mobile-sidebar ${isOpen ? 'open' : ''}`}>
            <nav>
              <ul className=''>
                <li><Link to="/" onClick={toggleSidebar}>Home</Link></li>
                <li><Link to="/about-us" onClick={toggleSidebar}>About Us</Link></li>
                <li className={`mobile-dropdown ${isMenuOpen ? 'dropdown-icon-down' : 'dropdown-icon-up'}`} onClick={toggleMenu}><Link to="#">Our Services <FaAngleDown /> </Link>
                  <ul className={`mobile-dropdownmenu ${isMenuOpen ? 'dropdown-show' : 'dropdown-hide'}`}>
                    <li><Link to="/web-development" onClick={toggleSidebar}>Web Development</Link></li>
                    <li><Link to="/graphic-design" onClick={toggleSidebar}>Graphic Design</Link></li>
                    <li><Link to="/seo" onClick={toggleSidebar}>SEO</Link></li>
                    <li><Link to="/social-media-marketing" onClick={toggleSidebar}>Social Media Marketing</Link></li>
                    <li><Link to="/content-writing" onClick={toggleSidebar}>Content Writing</Link></li>
                    <li><Link to="/ebook-writing" onClick={toggleSidebar}>Ebook Writing</Link></li>
                  </ul>
                </li>
                <li><Link to="/projects" onClick={toggleSidebar}>Our Projects</Link></li>
                <li><Link to="/contact-us" onClick={toggleSidebar}>Contact Us</Link></li>
              </ul>
            </nav>
            <div className='mobile-sidebar-contact'>
              <div className='flex items-center gap-2'>
                <HiOutlineMail className='text-2xl' />
                <span><a href="mailto:info@theSourcebee.com">info@thesourcebee.com</a></span>
              </div>
              <div className='flex items-center gap-2'>
                <LuPhoneCall className='text-2xl' />
                <span><a href="tel:+923435355310">+92 343 5355310</a></span>
              </div>
            </div>
            <div className='mobile-sidebar-socails'>
              <a href='https://www.facebook.com/Sourcebee/' target='blank' aria-label='Facebook'> <FaFacebookF /></a>
              <a href='https://www.instagram.com/Sourcebee/' target='blank' aria-label='Instagram'> <FaInstagram /></a>
              <a href='https://www.linkedin.com/company/theSourcebee/' target='blank' aria-label='Linkedin'> <FaLinkedinIn /></a>
              <a href='#' target='blank' aria-label='Whatsapp'> <FaWhatsapp /></a>
            </div>
          </div>
        </div>
        {/* ------------ Moblie nav ends ------------ */}
      </div>
    </header>
  )
}

export default Navbar
